import React, { useState, useEffect } from 'react';
export const Authenticate = () => {
  const [errorMessage, setErrorMessage] = useState('Authorizing please wait ....')
  const errorPage = "/errorPage"
  const setToLocalStorage = (contentDirectSession: any) => {
    let storefrontAuthToken = contentDirectSession.storefrontToken;
    localStorage.setItem('authorizationToken', storefrontAuthToken);
    localStorage.setItem('globalContent', JSON.stringify(contentDirectSession.globalContent));
    localStorage.setItem('channelServices', JSON.stringify(contentDirectSession.channelServices));
    if (contentDirectSession.wifiBundle) {
      localStorage.setItem('wifiBundle', JSON.stringify(contentDirectSession.wifiBundle));
    }
    const expireTime = Date.now() + (1000 * 60 * 30); // 30 minutes
    localStorage.setItem('expireTime', expireTime.toString());
    window.location.href = "/home"
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    try {
      if (queryParameters.has("uuid") && queryParameters.has("uuid_jwt")) {
        // Authorization for psgcode
        const apiURL = '/api/fedx/sat?uuidJwt=' + queryParameters.get("uuid_jwt");
        console.log('apiURL:', apiURL)
        const headers = {
          'Content-Type': 'application/json'
        };
        fetch(apiURL, {
          method: "GET",
          headers: headers
        })
          .then(async res => {
            console.log('res _ status: ', res.status);
            if (res.status >= 400) {
              let err = await res.json();
              console.log('Error: ' + err);
              setErrorMessage(err);
              window.location.href = errorPage;
            }else {
              let contentDirectSession = await res.json();
              setToLocalStorage(contentDirectSession);
            }
          })
          .catch(error => {
            console.log('Error:', error);
            setErrorMessage(error);
            window.location.href = errorPage;
          });
      }
      else if (queryParameters.has("code")) {
        // Authorization for psgcode
        const apiURL = '/api/xerxes/sat?code=' + queryParameters.get("code");
        console.log('apiURL:', apiURL)
        const headers = {
          'Content-Type': 'application/json'
        };
        fetch(apiURL, {
          method: "GET",
          headers: headers
        })
          .then(async res => {
            if (res.status >= 400) {
              let err = await res.json();
              console.log('Error:', err);
              setErrorMessage(err);
              window.location.href = errorPage;
            } else {
              let contentDirectSession = await res.json();
              setToLocalStorage(contentDirectSession);
            }
          })
          .catch(error => {
            console.log('Error:', error);
            setErrorMessage(error);
            window.location.href = errorPage;
          });
      } else {
        window.location.href = errorPage;
      }
    } catch (e) {
      console.log('Code Exception: ', e);
      setErrorMessage(e instanceof Error ? e.message : String(e));
      window.location.href = errorPage;
    }
  },[]);
  return (
    <main className="main-content" role='main'>
      <section className="hero-component logged-in">
        <h2>Xfinity On Campus</h2>
        <p>{errorMessage}</p>
      </section>
    </main>
  )
}
export default Authenticate;